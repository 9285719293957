body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}



/* certificates slider */
.slick-slide {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.slick-center .slick-slide img {
  transform: scale(1.1);
  /* Zoom effect for the center slide */
}

.slick-prev,
.slick-next {
  opacity: 0.5;
}

.slick-slide img {
  transition: transform 0.3s ease;
}

.slick-slide.slick-active img {
  transform: scale(1);
  /* Increase size of active slide */
}

.slick-slide:not(.slick-center) img {
  transform: scale(0.8);
  /* Decrease size of side slides */
  opacity: 0.7;
  /* Make side slides slightly less opaque */
}

/* For smaller screens, when centerMode is off */
@media (max-width: 768px) {
  .slick-slide img {
    opacity: 1 !important;
    /* Remove opacity effect for mobile devices */
  }
}

/* 
testimonial slick slider css */
.slick-center {
  transform: scale(1);
  /* Increase scale for emphasis */
  transition: transform 0.3s ease-in-out;
}

.slick-slide:not(.slick-center) {
  transform: scale(0.9);
  /* Reduce size for other slides */
  transition: transform 0.3s ease-in-out;
  opacity: 0.7;
  /* Reduce opacity for effect */
}

.slider-container {
  max-width: 1100px;
  max-height: 650px;
  margin: auto;
  position: relative;
  overflow: visible;
}

.slick-list {
  overflow: hidden !important;
}

.slick-slide {
  margin-bottom: 10px;
  padding: 10px 0;
}
.slick-slide {
  margin: 0 5px;
  /* Reduce the left & right margin */
  padding: 0;
  /* Remove padding if not needed */
}

